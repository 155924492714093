import Layout from '../components/layout'
import React from 'react'
import { Gen1Provider } from '../contexts/Gen1Context'
import { Gen2Provider } from '../contexts/Gen2Context'
import { HoneyProvider } from '../contexts/HoneyContext'
import Mate from '../components/mate'

export default function IndexPage({ data }) {

	return (
        <Layout prompt="Select The Bears That Wish To Breed">
			<p className="red">The estimated gas fee is based on the possibility of Triplets</p>
			<p className="alert">(Upon completed transaction your actual gas fee will be based on the number of cubs you receive, which will be less than the MetaMask estimated fee)</p>
			<Gen1Provider>
				<Gen2Provider>
					<HoneyProvider>
						<Mate/>
					</HoneyProvider>
				</Gen2Provider>
			</Gen1Provider>
        </Layout>
    )
}
