import React, { createContext, useContext, useEffect, useState } from 'react'
import { Web3Context } from './Web3Context'
import { useStaticQuery, graphql } from 'gatsby'

export const HoneyContext = createContext(null)

export const HoneyProvider = ({ children }) => {

	const { account, chainId, web3 } = useContext(Web3Context) || {}
	const [contract, setContract] = useState(null)

	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { sourceInstanceName: { eq: "contract-data" }, name: { eq: "TwoBitHoney" } }) {
				nodes {
					relativeDirectory
					name
					fields { contents }
				}
			}
		}
	`)

	async function loadContract() {
		if (chainId === null || chainId == 0 || web3 === null || web3 === undefined || web3.eth === null || web3.eth === undefined) {
			setContract(null)
			return
		}
		try {
			for (const node of data.allFile.nodes) {
				if (parseInt(node.relativeDirectory) == chainId) {
					const json = JSON.parse(node.fields.contents)
					setContract(new web3.eth.Contract(json.abi, json.address))
					return
				}
			}
			console.warn(`Could not find TwoBitCubs contract for chain ${chainId}`)
			setContract(null)
		} catch (error) {
			console.error(error)
			setContract(null)
		}
	}

	async function getHoneyCount(): Promise<number> {
		if (contract === null || contract === undefined || account === null) return 0
		return await contract.methods.balanceOf(account, 0).call()
	}

	useEffect(() => {
		loadContract()
	}, [chainId, web3])

	return (
		<HoneyContext.Provider value={{contract, getHoneyCount}}>
			{children}
		</HoneyContext.Provider>
	)
}
