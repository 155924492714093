import React, { useContext, useEffect, useState } from 'react'
import { Gen1Context } from '../contexts/Gen1Context'
import { Gen2Context } from '../contexts/Gen2Context'
import { HoneyContext } from '../contexts/HoneyContext'
import { Web3Context } from '../contexts/Web3Context'
import { navigate } from 'gatsby'
import Modal from './modal'
import OwnedBears, { ImageAttributes } from './owned-bears'
import * as styles from './mate.module.css'

export default function Mate() {

	const { contract: gen1Contract } = useContext(Gen1Context) || {}
	const { contract: gen2Contract, mateBears } = useContext(Gen2Context) || {}
	const { contract: honeyContract, getHoneyCount } = useContext(HoneyContext) || {}
	const { account, chainId } = useContext(Web3Context) || {}

	const [controlsDisabled, setControlsDisabled] = useState(false)
	const [selectedBearA, setSelectedBearA] = useState<ImageAttributes|null>(null)
	const [selectedBearB, setSelectedBearB] = useState<ImageAttributes|null>(null)
	const [selectDisabled, setSelectDisabled] = useState(false)
	const [showModalA, setShowModalA] = useState(false)
	const [showModalB, setShowModalB] = useState(false)
	const [honeyCount, setHoneyCount] = useState(0)

	function closeModal() {
		setShowModalA(false)
		setShowModalB(false)
	}

	async function mateClicked() {
		setControlsDisabled(true)
		try {
			await mateBears(selectedBearA.tokenId, selectedBearB.tokenId)
			switch (selectedBearA.species) {
				case 0: // Brown
					navigate("/05812377/", { replace: true })
					break
				case 1: // Black
					navigate("/38515876/", { replace: true })
					break
				case 2: // Polar
					navigate("/82137317/", { replace: true })
					break
				case 3: // Panda
					navigate("/93381126/", { replace: true })
					break
			}
		} catch (error) {
			// TODO: Notify the user with a Toast message
			if (error.code == 4001 || error.code == 4100) {
				console.log(`Adoption not authorized by user`)
			} else {
				console.error(error)
			}
		}
		setControlsDisabled(false)
	}

	function startSelectionA() {
		setShowModalA(true)
	}

	function startSelectionB() {
		setShowModalB(true)
	}

	function completeSelectionA(selectedBear: ImageAttributes) {
		console.log(`Selected bear a ${selectedBear.tokenId}`)
		setSelectedBearA(selectedBear)
		setSelectedBearB(null)
		setShowModalA(false)
	}

	function completeSelectionB(selectedBear: ImageAttributes) {
		console.log(`Selected bear b ${selectedBear.tokenId}`)
		setSelectedBearB(selectedBear)
		setShowModalB(false)
	}

	function contractsUnavailable() {
		return gen1Contract === null || gen1Contract === undefined || gen2Contract === null || gen2Contract === undefined || honeyContract === null || honeyContract === undefined
	}

	async function updateHoneyCount() {
		setHoneyCount(await getHoneyCount())
	}

	useEffect(() => {
		setSelectDisabled(contractsUnavailable())
	}, [gen1Contract, gen2Contract, honeyContract])

	useEffect(() => {
		setControlsDisabled(contractsUnavailable() || selectedBearA === null || selectedBearB === null)
	}, [gen1Contract, gen2Contract, honeyContract, selectedBearA, selectedBearB])

	useEffect(() => {
		updateHoneyCount()
		setSelectedBearA(null)
		setSelectedBearB(null)
	}, [account, chainId, honeyContract])

	return (
		<>
			<div className={styles.section}>
				<ul>
					<li onClick={selectDisabled ? null : startSelectionA}>
						<img src={selectedBearA?.image ?? "/img/bear-outline.png"} alt="Bear"/>
						{selectedBearA == null ?
							<p>Select Bear A</p> : <p className="selected">{selectedBearA.name}</p>
						}
					</li>
				</ul>
				<div className={styles.diamond}>
					<span>
						<img src="/img/honey.gif" alt="Honey Animation"/>
						<p>{honeyCount} Honey</p>
					</span>
					<div className={styles.diamondShape}/>
				</div>
				<ul>
					<li onClick={selectDisabled ? null : startSelectionB}>
						<img src={selectedBearB?.image ?? "/img/bear-outline.png"} alt="Bear"/>
						{selectedBearB == null ?
							<p>Select Bear B</p> : <p className="selected">{selectedBearB.name}</p>
						}
					</li>
				</ul>
			</div>
			<button className="initiate" disabled={controlsDisabled} onClick={mateClicked}><h4>Initiate Cloud Breeding Process</h4></button>
			{showModalA ? <Modal title="Select Bear A" onUserClose={closeModal}>
				<OwnedBears onUserSelection={completeSelectionA}/>
			</Modal> : <></>}
			{showModalB ? <Modal title="Select Bear B" onUserClose={closeModal}>
				<OwnedBears onUserSelection={completeSelectionB} existingSelection={selectedBearA}/>
			</Modal> : <></>}
		</>
	)
}
